<template>
	<div class="activity">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="全部" name="all"></el-tab-pane>
			<el-tab-pane label="日常活动" name="normal"></el-tab-pane>
			<el-tab-pane label="专场活动" name="spe"></el-tab-pane>
		</el-tabs>
		<e-table ref="tables" @query-Table-data="handleCurrentChange" :tableCols="activityTableConf" v-on:resetSearch="resetSearch" v-on:searchParamsChange="searchParamsChange" :showOperation="true" :dataOrigin="dataOrigin" :total="total" :currentPage="currentPage" :condition="form">
			<template #account2="scope">
				<div class="tc">
					<img height="60" width="60" :src="scope.rowData.a_logo" alt="">
					<div class="tc">{{scope.rowData.a_title}}</div>
				</div>
			</template>
			<template #customOperation>
				<el-col :span="1">
					<el-button @click="$router.push('/addActivity')">发布活动</el-button>
				</el-col>
			</template>
			<template #operation="scope">
				<div></div>
				<div class="cur_p" @click="kq(scope.rowData.id)" v-if="scope.rowData.status == 2">开启</div>
				<div class="cur_p"  @click="tz(scope.rowData.id)" v-if="scope.rowData.status == 1">停止</div>
				<router-link tag="a" target="" :to="{path:'/activeDetail',query: {id:scope.rowData.id}}">查看活动详情</router-link>
				<el-popover
						placement="bottom"
						title=""
						width="400"
						trigger="click">
					<div class="tc">
						<el-input v-model="copyUrl" disabled></el-input>
						<el-button class="mt20" @click="copyActive">点击复制</el-button>
					</div>
					<div class="cur_p" slot="reference" @click="fz(scope.rowData.id,$event)" v-if="scope.rowData.status == 1">复制活动链接</div>

<!--					<el-button slot="reference">立即邀请</el-button>-->
				</el-popover>
			</template>
		</e-table>
	</div>
</template>

<script>
import {activityTableConf} from '@data/activity'
import copy from "../../common/copy";
export default {
	name: 'Activity',
	data() {
		return {
			activityTableConf,
			dataOrigin: {
				data: []
			},
			form: {},
			activeName: "all",
			currentPage: 1,
			pageSize: 10,
			total: 0,
			condition: {},
			mouseover: false,
			copyUrl: ''
		}
	},
	methods: {
		handleCurrentChange(data){
			this.$refs.tables.loading = true
			this.currentPage = data.currentPage
			this.GetActiveLists()
		},
		resetSearch(){
			this.$refs.tables.loading = true
			this.form = {}
			this.condition = {}
			this.currentPage = 1
			this.GetActiveLists()
		},
		searchParamsChange(e) {
			this.$refs.tables.loading = true
			this.condition = e.condition
			this.currentPage = 1
			this.GetActiveLists()
		},
		mfz(id,e){
			let that = this
			that.get('?server=live.active.invite.url.handle',{
				id:id}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				copy(res.data.origin, e)

			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		copyActive(e){
			copy(this.copyUrl, e)
		},
		fz(id,e){
			console.log(e)
			let that = this
			that.copyUrl = ''
			let loading = this.$loadings.service({
				'text': "请求中",
				background: 'rgba(28,28,28,0.7)',
			})
			that.get('?server=live.active.invite.url.handle',{
				id:id}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.copyUrl = res.data.origin
				// copy(res.data.url, e)
				loading.close()

				// let clipboard = new Clipboard('.cur_p', {
				// 	text: function () {
				// 		return res.data.url
				// 	}
				// })
				// clipboard.on('success', () => {
				// 	// 释放内存
				// 	that.$message({
				// 		type: 'success',
				// 		message: '复制成功!'
				// 	});
				// 	clipboard.destroy()
				// })
				// clipboard.on('error', () => {
				// 	that.$message({
				// 		type: 'error',
				// 		message: '复制失败!'
				// 	});
				// 	clipboard.destroy()
				// })

				// const clipboard = new Clipboard(e.target, {
				// 	text: () => res.data.url
				// })
				// clipboard.on('success', () => {
				// 	that.$message({
				// 		type: 'success',
				// 		message: '复制成功!'
				// 	});
				// 	// 释放内存
				// 	clipboard.destroy()
				// })
				// clipboard.on('error', () => {
				// 	// 不支持复制
				// 	that.$message({
				// 		type: 'error',
				// 		message: '复制失败!'
				// 	});
				// 	// 释放内存
				// 	clipboard.destroy()
				// })
				// // 解决第一次点击不生效的问题，如果没有，第一次点击会不生效
				// clipboard.onClick(e)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
				loading.close()
			});
		},
		kq(id){
			let that = this

			this.$confirm('是否开启该活动?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.active.handle',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '开启成功!'
					});
					that.GetActiveLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消邀请'
				});
			});
		},
		tz(id){
			let that = this

			this.$confirm('是否停止该活动?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.post('?server=live.active.handle&method=back',{
					id:id}).
				then(function () {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					that.$message({
						type: 'success',
						message: '停止成功!'
					});
					that.GetActiveLists()
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消邀请'
				});
			});
		},
		handleClick() {
			this.currentPage = 1
			this.$refs.tables.currentPage = 1
			this.form = {}
			this.condition = {}
			this.$refs.tables.loading = true
			this.$refs.tables && this.$refs.tables.initData()
			this.GetActiveLists()
		},
		GetActiveLists(){
			let that = this
			this.dataOrigin.data = []
			that.$refs.tables && that.$refs.tables.initData()
			this.currentPage = that.$refs.tables ? that.$refs.tables.currentPage : 1
			this.get('?server=live.active.lists.get',{a_type:this.activeName,
				page:this.currentPage,
				query:this.condition.account2,
				status:this.condition.statusInfo,
				starttime:this.condition.starttime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				// that.dataOrigin.data = response.data.data
				that.total = response.data.total
				that.$refs.tables.total = response.data.total
				that.$refs.tables.loading = false
				that.$refs.tables.initData()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				that.$refs.tables.loading = false
				console.log(error);
			});
		},
	},
	created() {
		this.$nextTick(() => {
			this.$refs.tables.loading = true
		});
		this.GetActiveLists()
	}
}
</script>