export const activityTableConf = [
	{
		label: '活动时间',
		prop: 'starttime',
		search: true,
		type: 'daterange'
	},
	{
		label: '活动名称',
		prop: 'account2',
		search: true,
		type: 'input'
	},
	{
		label: '活动信息',
		prop: 'a_require'
	},
	{
		label: '报名截止时间',
		prop: 'baotime'
	},
	{
		label: '活动要求',
		prop: 'a_require'
	},
	{
		label: '活动类型',
		prop: 'type'
	},
	// {
	// 	label: '报名商品',
	// 	prop: 'account6'
	// },
	{
		label: '状态',
		prop: 'statusInfo',
		search: true,
		type: 'select',
		options: [
			{
				label: '开启',
				value: 1
			},
			{
				label: '停止',
				value: 2
			},
		]
	},
	{
		label: '操作',
		prop: 'operation',
		width: 140
	}
]

import appConfig from '../common/app.js'
let UploadUrl = appConfig.api_request_url + '?server=upload.file'
export const addActivityFormConfList = [
	{
		label: '活动图片',
		prop: 'pict_url',
		type: 'upload',
		action: UploadUrl,
		accept: 'image/jpeg,image/gif,image/png'
	},
	{
		label: '活动类型',
		prop: 'activeType',
		search: true,
		type: 'select',
		options: [
			{
				name: '日常活动',
				id: 1
			},
			{
				name: '专场活动',
				id: 2
			},
		]
	},
	{
		label: '直播日期',
		prop: 'zbtime',
		type: 'date',
	},
	{
		label: '活动名称',
		prop: 'name',
		type: 'input',
	},
	{
		label: '报名截止日期',
		prop: 'baotime',
		type: 'date',
	},
	{
		label: '目标商品数',
		prop: 'goodsNum',
		type: 'input',
	},
	{
		label: '活动开始时间',
		prop: 'starttime',
		type: 'date',
	},
	{
		label: '活动结束时间',
		prop: 'endtime',
		type: 'date',
	},
	{
		label: '活动要求',
		prop: 'a_require',
		type: 'textarea',
	},
	{
		label: '活动详情',
		prop: 'content',
		type: 'textarea',
	},
]

export const goodsStructTableConf = [
	{
		label: '类目',
		prop: 'account1',
	},
	{
		label: '商品',
		prop: 'account2',
	},
	{
		label: '价格区间',
		prop: 'account3'
	},
	{
		label: '商品数量',
		prop: 'account4'
	},
	{
		label: '意向品牌',
		prop: 'account5'
	},
	{
		label: '操作',
		prop: 'operation'
	}
]

export const activityEditFormConf = [
	{
		label: '活动图片',
		prop: '1',
		type: 'solt'
	},
	{
		label: '活动名称',
		prop: '2',
		type: 'input'
	},
	{
		label: '活动日期',
		prop: '3',
		type: 'daterange'
	},
	{
		label: '报名截至日期',
		prop: '5',
		type: 'date'
	},
	{
		label: '活动要求',
		prop: '6',
		type: 'textarea'
	},
	{
		label: '活动详情',
		prop: '7',
		type: 'solt'
	}
]